import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import KTBillabilityLoader from '../common/KTBillabilityLoader';
import { isEmptyOrNull, getPostData, isAnalyst } from 'utils';
import './index.scss';
import { isEqual, formatDate, isLoading } from 'utils/common';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';
import { selectors } from 'redux/reducers';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import KTCheckbox from 'components/common/KTCheckbox';

const KTTimeSheetTable = props => {
  const { appliedFilter, memberList, loggedInUserRole, timesheetData } = props;
  const [selectedFilters, setSelectedFilters] = useState(
    CONFIG.TIMESHEET_FILTERS
  );
  const [showTooltip, setShowToolTip] = useState({});
  const [apiData, setApiData] = useState(timesheetData);

  const history = useHistory();
  if (isAnalyst(loggedInUserRole)) {
    history.push(CONFIG.PROTOTYPE_URLS.HOME);
  }

  useEffect(() => {
    getApiData();
  }, [appliedFilter]);

  useEffect(() => {
    setApiData(timesheetData);
  }, [timesheetData]);

  const getApiData = () => {
    const dataToPost = getPostData(appliedFilter, memberList);
    if (isEmptyOrNull(dataToPost)) return;
    props.fetchTimesheetData(
      API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA,
      dataToPost
    );
  };

  const handleCheckboxChange = selectedFilter => {
    const updatedFilters = selectedFilters.map(filter => {
      if (filter.name === selectedFilter) {
        filter.isChecked = !filter.isChecked;
      }
      return filter;
    });
    setSelectedFilters(updatedFilters);
  };

  const getVisibility = status => {
    let className = '';
    selectedFilters.map(filter => {
      if (isEqual(filter.name, status) && !filter.isChecked)
        className = 'hideColumn';
      if (
        (isEqual(status, 'Over Theo Cap') ||
          isEqual(status, 'Below Theo Cap')) &&
        isEqual(filter.name, 'Over/Under theo cap') &&
        !filter.isChecked
      )
        className = 'hideColumn';
    });
    return className;
  };

  const renderBillabilityChart = () => {
    let data = apiData?.data || {};
    let headerColumns = data?.allWeeks || [];
    let employeesData = data?.employees || {};
    let keys = Object.keys(headerColumns);
    return (
      <React.Fragment>
        <div className="timesheet_filters">
          {CONFIG.TIMESHEET_FILTERS.map(filter => {
            return (
              <div className="filter-display">
                <KTCheckbox
                  checked={filter.isChecked}
                  checkboxColor="darkest-blue"
                  handleChange={handleCheckboxChange}
                  isReturn={true}
                  value={filter.name}
                  name={filter.name}
                />
                <div className="filter-name">{filter.name}</div>
              </div>
            );
          })}
        </div>
        <div className="timeSheet-table">
          <div className="timeSheet-table__inner">
            <table className="timeSheet-table__inner__main">
              <tr className="timeSheet-table__inner__main__header">
                <th className="header-1">Name</th>
                <th className="header-2">On-Time</th>
                {keys.map(key => {
                  return (
                    <td className="heading" key={key}>
                      {formatDate(key, 'MMM-dd, yyyy')}
                    </td>
                  );
                })}
              </tr>
              {Object.keys(employeesData).map(key => {
                const val = employeesData[key];
                return (
                  <tr className="timeSheet-table__inner__main__body" key={key}>
                    <th className="header-1">{key}</th>
                    <th className="header-2">{`${val.onTimePercentage}%`}</th>
                    {keys.map((colunsKey, index) => {
                      if (!isEmptyOrNull(val.weeklyDetails)) {
                        const weeklyDetails = val.weeklyDetails.find(
                          e => e.weekPostedDate === colunsKey
                        );
                        if (!isEmptyOrNull(weeklyDetails)) {
                          const tooltipClassName = showTooltip[
                            `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
                          ]
                            ? 'on'
                            : 'off';
                          return (
                            <td
                              className={`value ${
                                !isEqual(
                                  weeklyDetails.hoursSubmitted,
                                  weeklyDetails.theoCapHrs
                                ) && !isEqual(weeklyDetails.hoursSubmitted, '0')
                                  ? 'highlighted'
                                  : ''
                              } ${weeklyDetails.status} ${getVisibility(
                                weeklyDetails.status
                              )}`}
                            >
                              <span
                                className="tooltip-with-icon periodTooltip desc"
                                onMouseEnter={() => {
                                  const newState = {};
                                  newState[
                                    `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
                                  ] = true;
                                  setShowToolTip({ ...newState });
                                }}
                                onMouseLeave={() => {
                                  const newState = {};
                                  newState[
                                    `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
                                  ] = false;
                                  setShowToolTip({ ...newState });
                                }}
                              >
                                <div>
                                  {isEqual(weeklyDetails.status, 'Missing')
                                    ? 'Missing'
                                    : isEqual(weeklyDetails.status, 'Late')
                                    ? 'Late'
                                    : weeklyDetails.hoursSubmitted}
                                </div>
                                <div id="tooltip" className={tooltipClassName}>
                                  <div className="tooltip-inner">
                                    <div className="inner-row">
                                      <div className="inner-row_heading">
                                        Week Posted Date:
                                      </div>
                                      <div className="inner-row_val">
                                        {weeklyDetails.weekPostedDate}
                                      </div>
                                    </div>
                                    <div className="inner-row">
                                      <div className="inner-row_heading">
                                        Hours Submitted:
                                      </div>
                                      <div className="inner-row_val">
                                        {weeklyDetails.hoursSubmitted}
                                      </div>
                                    </div>
                                    <div className="inner-row">
                                      <div className="inner-row_heading">
                                        Theo Cap Hrs:
                                      </div>
                                      <div className="inner-row_val">
                                        {weeklyDetails.theoCapHrs}
                                      </div>
                                    </div>
                                    <div className="inner-row">
                                      <div className="inner-row_heading">
                                        Status:
                                      </div>
                                      <div className="inner-row_val">
                                        {weeklyDetails.status}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </td>
                          );
                        } else {
                          return <td className="grey" />;
                        }
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="kt-billability-container">
      <div className="kt-billability-container__chart">
        {isLoading(apiData) ? (
          <KTBillabilityLoader />
        ) : (
          renderBillabilityChart()
        )}
      </div>
    </div>
  );
};

const mapStateToProps = store => {
  return {
    appliedFilter: store.filterDataPt.filters,
    memberList: store.filterDataPt.fetchTeamMemberList?.results || [],
    loggedInUserRole: store.homePageData.loggedInUserRole,
    timesheetData: {
      isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
        store,
        API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA
      ),
      data: selectors.apiSelector.getResults(
        store,
        API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA
      ),
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTimesheetData: bindActionCreators(
      actions.apiActions.fetchApiData,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KTTimeSheetTable);
