import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import CONFIG from 'config/configProps';
import './KTHeader.scss';
import { isEqual } from 'utils/common';
import { Grid } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';
import { isEmptyOrNull } from 'utils';
import ImpersonatorList from 'components/common/KtImpersonator';

const KTHeader = props => {
  const impersonatorRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [toggleState, setToggleState] = useState({});
  const [selectedText, setSelectedText] = useState('Select');
  const [showMembers, setShowMembers] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        showMembers &&
        impersonatorRef.current &&
        !impersonatorRef.current.contains(e.target)
      ) {
        setShowMembers(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMembers]);

  const goToHome = () => {
    history.push({
      pathname: CONFIG.PROTOTYPE_URLS.HOME,
      state: { optionIndex: 0 },
    });
    props.updateSelectedIndex(0);
  };

  const isActive = url => {
    return isEqual(location.pathname, url);
  };

  const toggleDropDown = (event, item) => {
    event.stopPropagation();
    let newState = { ...toggleState };
    Object.keys(newState).forEach(key => {
      newState[key] = false;
    });
    newState[item.name] = !toggleState[item.name];
    setToggleState({ ...newState });
  };

  const handleOptionClick = (index, url) => {
    props.updateSelectedIndex(index);
    history.push({
      pathname: url,
      state: { optionIndex: index },
    });
  };

  const navigateToUrl = (event, hasSubMenu, item) => {
    hasSubMenu
      ? toggleDropDown(event, item)
      : history.push({
          pathname: item.url,
        });
  };

  const applyView = memberDetails => {
    setSelectedText(memberDetails?.employeeName || 'Select');
    setShowMembers(false);
  };
  return (
    <Grid className="Kt_dashboard_header">
      <Grid.Column width={2} className="marginR50">
        <img
          src="/img/kt-logo.svg"
          alt="kt dashboard logo"
          className="Kt_dashboard_header_logo"
          onClick={goToHome}
        />
      </Grid.Column>
      {!isEmptyOrNull(props.loggedInUserRole) &&
      !isEqual(props.loggedInUserRole, CONFIG.ANALYST_ROLE)
        ? CONFIG.PROTOTYPE_HEADING.map((item, index) => {
            const hasSubMenu = isEqual(index, 0) || isEqual(index, 2);
            const tabs = isEqual(index, 0)
              ? CONFIG.BILLING_TABS
              : CONFIG.CHARGES_HOURS_TABS;
            return (
              <Grid.Column
                width={item.name === 'Charges/Hours' ? 2 : 1}
                key={item.name}
                className={`${
                  hasSubMenu ? 'heading-with-dd' : ''
                } fit-content ${isActive(item.url) ? 'active' : ''} `}
                onClick={e => navigateToUrl(e, hasSubMenu, item)}
              >
                <div
                  className={`${
                    hasSubMenu ? 'heading-with-dd_nabs' : ''
                  } Kt_dashboard_header_nabs`}
                >
                  {item.name}
                </div>
                {hasSubMenu && (
                  <>
                    <img
                      src="../img/kt-scorecard-white-arrow.svg"
                      alt="arrow-icon"
                      className={toggleState[item.name] ? 'rotate180' : ''}
                    />
                    {toggleState[item.name] && (
                      <div className="billing_options">
                        {tabs.map((tab, index) => {
                          return (
                            <div
                              className="billing_options__option"
                              key={tab}
                              onClick={() => handleOptionClick(index, item.url)}
                            >
                              {tab}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </Grid.Column>
            );
          })
        : null}
      <Grid.Column width={2} floated="right" className="rightAlign">
        {props.userDetails?.impersonator && (
          <div className="impersonator-container" ref={impersonatorRef}>
            <p className="impersonator">User Validation</p>
            <p
              onClick={() => setShowMembers(!showMembers)}
              className="select-impersonator"
            >
              <span className="text">{selectedText}</span>
              <img
                src="../img/kt-scorecard-white-arrow.svg"
                alt="arrow-icon"
                className={'kt-drop-arrow'}
              />
            </p>
            {showMembers && <ImpersonatorList applyView={applyView} />}
          </div>
        )}
      </Grid.Column>
      {/* <Grid.Column width={1} floated="right" className="rightAlign"> */}
      {/*commenting this code becuse of post MVP implementation
        <img
          className="Kt_dashboard_header_help"
          src="../img/help.png"
          alt="help"
        />
        */}
      {/* </Grid.Column> */}
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    loggedInUserRole: store.homePageData.loggedInUserRole,
    userDetails: store.homePageData.fetchUserDetails?.results || {},
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateSelectedIndex: bindActionCreators(
      actions.protoTypeActions.updateSelectedIndex,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KTHeader);
