import { connect } from 'react-redux';
import KTHome from './KTHome';
import { bindActionCreators } from 'redux';
import { selectors } from 'redux/reducers';
import actions from 'redux/actions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';

/**
 * method to create PROPS for seed Data  by fetching them from Store, using selectors
 * @param {*} state Application State
 * @param {*} apiIdentifier API Identifier (State Identifier)
 */
function getPropsForSeedData(state, apiIdentifier) {
  return {
    isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
      state,
      apiIdentifier
    ),
    data: selectors.ktHomeSelectors.getResults(state, apiIdentifier),
  };
}

/**
 * method to create PROPS for seed Data  by fetching them from Store, using selectors
 * @param {*} state Application State
 * @param {*} apiIdentifier API Identifier (State Identifier)
 */
function getPropsForSeedDataFilters(state, apiIdentifier) {
  return {
    isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
      state,
      apiIdentifier
    ),
    data: selectors.filterSectionSelectorsPt.getResults(state, apiIdentifier),
  };
}

/**
 * Method to  Format the State and pass them as Props to the component
 * @param {*} state  application State
 */
function mapStateToProps(state) {
  return {
    lastUpdatedDate: state.filterDataPt.lastUpdatedDate,
    appliedFilters: state.filterDataPt.filters,
    billabilityTrendsDataMember: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_BILLABILITY_TREND_MEMBER
    ),
    billabilityTrendsDataBusiness: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_BILLABILITY_TREND_BUSINESS
    ),
    timeSheetDataMember: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TIMESHEET_MEMBER
    ),
    timeSheetDataBusiness: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TIMESHEET_BUSINESS
    ),
    topDownloadsDataMember: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TOP_DOWNLOADS_MEMBER
    ),
    topDownloadsDataBusiness: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TOP_DOWNLOADS_BUSINESS
    ),
    topClientsDataMember: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TOP_CLIENTS_MEMBER
    ),
    topClientsDataBusiness: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TOP_CLIENTS_BUSINESS
    ),
    teamSizeData: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TEAM_SIZE
    ),
    engagementDepthDataMember: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_ENGAGMENT_DEPTH_MEMBER
    ),
    engagementDepthDataBusiness: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_ENGAGMENT_DEPTH_BUSINESS
    ),
    ktPulseCheckData: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_KT_PULSE_CHECK
    ),
    ktBillabilityData: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_KT_BILLABILITY
    ),
    hourlyBillRateData: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_HOURLY_BILL_RATE
    ),
    userDetails: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_USER_DETAILS
    ),
    cdBillabilityDataMember: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_CD_BILLABILITY_MEMBER
    ),
    cdBillabilityDataBusiness: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_CD_BILLAILITY_BUSINESS
    ),
    topProjectsDataBusiness: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TOP_PROJECTS_BUSINESS
    ),
    topProjectsDataMember: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TOP_PROJECTS_MEMBER
    ),
    practiceArea: getPropsForSeedDataFilters(
      state,
      API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST
    ),
    geoMarket: getPropsForSeedDataFilters(
      state,
      API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST
    ),
    rmsSatisfactionDataMember: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_RMS_SATISFACTION_MEMBER
    ),
    rmsSatisfactionDataBusiness: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_RMS_SATISFACTION_BUSINESS
    ),
    knMaterialDataMember: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_KN_MATERIALS_MEMBER
    ),
    knMaterialDataBusiness: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_KN_MATERIALS_BUSINESS
    ),
    defaultUserFilters: getPropsForSeedDataFilters(
      state,
      API_CALL_IDENTIFIERS.FETCH_DEFAULT_USER_FILTERS
    ),
    selectedIndexFromStore: state.filterDataPt.selectedIndex,
    selectedKtPulseQuestion: state.homePageData.selectedKtPulseQuestion,
    selectedBillabilityQuestion: state.homePageData.selectedBillabilityQuestion,
    selectedTopProjectsQuestion: state.homePageData.selectedTopProjectsQuestion,
    selectedIdMember: state.homePageData.selectedMemberId,
    isMemberFilterSelected: state.filterDataPt.isMemberFilterSelected,
    selectedView: state.homePageData.selectedView,
    selectedUserByImpersonator: state.homePageData.selectedUserByImpersonator,
    loggedInUserRole: state.homePageData.loggedInUserRole,
    isDataReset: state.homePageData.isResetData,
    adobeData: state.adobeData.adobeAnalyticsData,
    loggedInUserId: state.homePageData.loggedInUserId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchFiltersData: bindActionCreators(
        actions.protoTypeActions.fetchSeedDataFilters,
        dispatch
      ),
      fetchMemberData: bindActionCreators(
        actions.homepageActions.fetchSeedDataMemberView,
        dispatch
      ),
      fetchBusinessData: bindActionCreators(
        actions.homepageActions.fetchSeedDataBusinessView,
        dispatch
      ),
      updateSelectedIndex: bindActionCreators(
        actions.protoTypeActions.updateSelectedIndex,
        dispatch
      ),
      fetchUserData: bindActionCreators(
        actions.protoTypeActions.fetchMemberList,
        dispatch
      ),
      saveMemberId: bindActionCreators(
        actions.homepageActions.saveSelectedUserIdMember,
        dispatch
      ),
      saveAppliedFilters: bindActionCreators(
        actions.protoTypeActions.saveAppliedFilters,
        dispatch
      ),
      saveSelectedView: bindActionCreators(
        actions.homepageActions.saveSelectedView,
        dispatch
      ),
      resetData: bindActionCreators(
        actions.homepageActions.updateIsResetData,
        dispatch
      ),
      updateAdobeDetails: bindActionCreators(
        actions.adobeAnalyticsActions.updateAdobeDetails,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KTHome);
