import CONFIG from 'config/configProps';
import {
  isEqual,
  isEmpty,
  isArray,
  cloneDeep,
  formatDate,
  isSingleDigit,
  forEach,
  isLoading,
} from './common';
import moment from 'moment';
import queryString from 'query-string';

import { updateShowPageContentStatus } from 'redux/actions';
import { updateLoggedInUserHighestRole } from 'redux/actions/userRoleAction';
import { updateFilters } from 'redux/actions/headerFilterAction';
import { getCheckedOption } from './dd';

export const sortByString = (data, keyName, orderBy = 'ascending') => {
  return data.sort((a, b) => {
    a = keyName ? a[keyName] : a;
    b = keyName ? b[keyName] : b;

    return orderBy === 'ascending' ? a.localeCompare(b) : b.localeCompare(a);
  });
};

export const sortByNumber = (data, keyName, orderBy = 'ascending') => {
  return data.sort((a, b) => {
    a = keyName ? a[keyName] : a;
    b = keyName ? b[keyName] : b;
    return orderBy === 'ascending'
      ? parseInt(a) - parseInt(b)
      : parseInt(b) - parseInt(a);
  });
};

export const sortByDate = (data, keyName, orderBy = 'ascending') => {
  return data.sort((a, b) => {
    a = keyName ? a[keyName] : a;
    b = keyName ? b[keyName] : b;
    if (keyName && keyName === 'fileLastUpdatedDateTime') {
      a = a.split(' ').join('T');
      b = b.split(' ').join('T');
    }

    return orderBy === 'ascending'
      ? new Date(a) - new Date(b)
      : new Date(b) - new Date(a);
  });
};

export function sortData(data, comparator, keyName, orderBy = 'ascending') {
  if (!data || !data.length) {
    return;
  }

  switch (comparator) {
    case 'string':
      return sortByString(data, keyName, orderBy);
    case 'number':
      return sortByNumber(data, keyName, orderBy);
    case 'date':
      return sortByDate(data, keyName, orderBy);
    default:
      return data;
  }
}

export function short_months(monthIndex) {
  Date.shortMonths = ['null', ...CONFIG.MONTH_SORT_NAMES];
  return Date.shortMonths[monthIndex];
}

export function getHighestRoleName(allRolesLevelList, assignedRolesList = []) {
  let indexList = [];

  indexList = assignedRolesList.map(role => {
    return allRolesLevelList.indexOf(role);
  });

  const highestRoleIndex = Math.min(...indexList);
  return allRolesLevelList[highestRoleIndex];
}

export function isInSuperAdminGroupOfOktaForKtScorecard(userDetails) {
  if (userDetails) {
    return userDetails.userRoles &&
      userDetails.userRoles.includes(
        CONFIG.KT_SCORECARD_CONST.SUPER_ADMIN_GROUP_NAME
      )
      ? true
      : false;
  }

  return false;
}

export const getViewName = (empActualData, allRolesList, userDetails) => {
  let { employeeRoleDTO } = empActualData;
  let rolesList = [];
  let hightestRoleName = '';
  // if user belongs to super admin group of okta
  // update user role to 'Super Admin'
  if (isInSuperAdminGroupOfOktaForKtScorecard(userDetails)) {
    return CONFIG.ROLES.SUPER_ADMIN_ROLE;
  }

  if (!employeeRoleDTO || employeeRoleDTO.length === 0) {
    return hightestRoleName;
  }

  rolesList = employeeRoleDTO.map(item => {
    return item.roleName;
  });

  const roleCheckOrder = [
    {
      roleName: CONFIG.ROLES.SUPER_ADMIN_ROLE,
      roleCheckMethod: isSuperAdminRole,
    },
    {
      roleName: CONFIG.ROLES.GEO_LEAD_ROLE,
      roleCheckMethod: isGeoLeadRole,
    },
    {
      roleName: CONFIG.ROLES.PA_ADMIN_ROLE,
      roleCheckMethod: isPaAdminRole,
    },
    {
      roleName: CONFIG.ROLES.PA_ADMIN_VIEWER_ROLE,
      roleCheckMethod: isPaAdminViewerRole,
    },
  ];

  for (const { roleName, roleCheckMethod } of roleCheckOrder) {
    for (const role of rolesList) {
      if (roleCheckMethod(role)) {
        return roleName;
      }
    }
  }

  return getRoleByEmployeePA(empActualData, employeeRoleDTO, allRolesList);
};

const getRoleByEmployeePA = (empActualData, employeeRoleDTO, allRolesList) => {
  const tempRolesList = [];
  employeeRoleDTO.forEach(item => {
    tempRolesList.push(item.roleName);
  });

  if (tempRolesList.length > 1) {
    if (tempRolesList.some(isGeoManagerRole) && empActualData?.geoRegion) {
      return CONFIG.ROLES.GEO_MANAGER_ROLE;
    }

    return getHighestRoleName(allRolesList, tempRolesList);
  }

  if (tempRolesList.length === 1) {
    return tempRolesList[0];
  }

  return '';
};

export function getDateTimeStringForHeader() {
  const header3Date = new Date();
  const day = header3Date.getDate();
  const month_index = header3Date.getMonth();
  const year = header3Date.getFullYear().toString().slice(-2);
  const month_names = CONFIG.MONTH_SORT_NAMES;
  let hours =
    header3Date.getHours() > 12
      ? header3Date.getHours() - 12
      : header3Date.getHours();
  const am_pm = header3Date.getHours() >= 12 ? 'PM' : 'AM';
  hours = hours < 10 ? `0${hours}` : hours;
  const minutes =
    header3Date.getMinutes() < 10
      ? `0${header3Date.getMinutes()}`
      : header3Date.getMinutes();
  let time = `${hours}:${minutes} ${am_pm}`;
  return `${time} - ${day} ${month_names[month_index]} ${year}`;
}

export function selectedDates(initialDate, date, option, isNewFormat) {
  let startDate, endDate, selectedDate;
  if (date) {
    selectedDate = date;
  } else {
    selectedDate = initialDate;
  }
  const monthFormat = isNewFormat ? 'MM' : 'M';
  const dateFormat = isNewFormat ? 'DD' : 'D';
  let selectedYear = moment(selectedDate).format('YYYY');
  let selectedMonth = moment(selectedDate).format(monthFormat);
  let lastDayOfMonth = new Date(selectedYear, selectedMonth, 0).getDate();
  let selectedOption = option.split(' ');
  let optionVal = selectedOption[1];
  if (option.includes('Months')) {
    let newStartDate = cloneDeep(selectedDate);
    let modifiedDate = new Date(
      newStartDate.setMonth(selectedMonth - optionVal)
    );
    let year = moment(modifiedDate).format('YYYY');
    let day = moment(modifiedDate).format('DD');
    let month =
      moment(modifiedDate).format(monthFormat) % 12
        ? moment(modifiedDate).format(monthFormat) % 12
        : 12;
    if (isNewFormat) {
      startDate = `${year}-${isSingleDigit(month) ? `0${month}` : month}-01`;
      endDate = `${selectedYear}-${selectedMonth}-${day}`;
    } else {
      startDate = `${year}-${month}-1`;
      endDate = `${selectedYear}-${selectedMonth}-${lastDayOfMonth}`;
    }
  } else if (isEqual(option, 'TTM')) {
    let newStartDate = cloneDeep(selectedDate);
    let newEndDate = cloneDeep(selectedDate);
    let modifiedStartDate = new Date(newStartDate.setMonth(selectedMonth - 13));
    let modifiedEndDate = new Date(newEndDate.setMonth(selectedMonth - 2));
    let endDateYear = moment(modifiedEndDate).format('YYYY');
    let endDateMonth = getSelectedMonth(modifiedEndDate, monthFormat);
    let lastDayOfEndMonth = new Date(endDateYear, endDateMonth, 0).getDate();
    let startDateYear = moment(modifiedStartDate).format('YYYY');
    let startDateMonth = getSelectedMonth(modifiedStartDate, monthFormat);
    endDate = `${endDateYear}-${endDateMonth}-${lastDayOfEndMonth}`;
    startDate = `${startDateYear}-${startDateMonth}-01`;
  } else if (option.includes('Weeks')) {
    const date = new Date();
    const lastDay = date.getDate() - date.getDay();
    const lastDate = new Date(date.setDate(lastDay));
    const firstDate = cloneDeep(lastDate);
    const firstDay = new Date(
      firstDate.setDate(firstDate.getDate() - (7 * optionVal - 1))
    );
    let year = moment(firstDay).format('YYYY');
    let day = moment(firstDay).format(dateFormat);
    let month =
      moment(firstDay).format(monthFormat) % 12
        ? moment(firstDay).format(monthFormat) % 12
        : 12;
    if (isNewFormat) {
      startDate = `${year}-${isSingleDigit(month) ? `0${month}` : month}-${
        isSingleDigit(day) ? `0${day}` : day
      }`;
    } else {
      startDate = `${year}-${month}-${day}`;
    }
    endDate = `${selectedYear}-${selectedMonth}-${moment(lastDate).format(
      isNewFormat ? 'DD' : 'D'
    )}`;
  } else {
    switch (option) {
      case 'YTD':
        startDate = isNewFormat
          ? `${selectedYear}-01-01`
          : `${selectedYear}-1-1`;
        endDate = `${selectedYear}-${selectedMonth}-${lastDayOfMonth}`;
        break;
      case 'Quarter 1':
        startDate = `${selectedYear}-1-1`;
        endDate = `${selectedYear}-3-31`;
        break;
      case 'Quarter 2':
        startDate = `${selectedYear}-4-1`;
        endDate = `${selectedYear}-6-30`;
        break;
      case 'Quarter 3':
        startDate = `${selectedYear}-7-1`;
        endDate = `${selectedYear}-9-30`;
        break;
      case 'Quarter 4':
        startDate = `${selectedYear}-10-1`;
        endDate = `${selectedYear}-12-31`;
        break;
      default:
        startDate = `${selectedYear}-${selectedMonth}-1`;
        endDate = `${selectedYear}-${selectedMonth}-${lastDayOfMonth}`;
    }
  }
  let dateSelected = {};
  dateSelected.startDate = startDate;
  dateSelected.endDate = endDate;
  return dateSelected;
}

function getSelectedMonth(month, monthFormat) {
  let modifiedMonth =
    moment(month).format(monthFormat) % 12
      ? moment(month).format(monthFormat) % 12
      : 12;
  return isSingleDigit(modifiedMonth) ? `0${modifiedMonth}` : modifiedMonth;
}

export function getApiFailMessage(isError, apiMessage) {
  const message =
    apiMessage ||
    (isError
      ? CONFIG.KT_SCORECARD_CONST.DEFAULT_ERROR_MESSAGE
      : CONFIG.KT_SCORECARD_CONST.DEFAULT_ERROR_MESSAGE_FOR_API_FAIL);
  return {
    showToastNotification: true,
    notificationHeader: 'Error',
    notificationBody: message,
    notificationClassName: 'custom-notification',
  };
}

export function getSelectedTimePeriod(date) {
  let selectedDate = {};
  selectedDate.year = moment(date).format('YYYY');
  selectedDate.month = moment(date).format('M');
  return selectedDate;
}

export function getSearchByNameQuery(searchTerm) {
  return `query {
    searchFilter(aliasMatching: ["title"], 
      compositeFilter: {logicOperator: AND, 
        subFilters: [
          {logicOperator: OR,subFilters: [{valueFilter: {field: "searchTerm=*${searchTerm}*"}}]}, 
          {logicOperator: OR, subFilters: [{valueFilter: {field: "alumni=NO"}}]},
          {logicOperator: NOT, subFilters: [{valueFilter: {field: "name=Anonymized"}}]}
        ]
      },
      dataSet: BCG_ALL, limit: 60, offset: 0) {
      totalCount
      employees {
        id
        profilePicture
        name
        firstName
        lastName
        title,
		email
      }
    }
  }
  `;
}

/**
 * method to return the query parameters
 * @param {*} locationSearch location.search from router
 */
export const getQueryParams = locationSearch =>
  queryString.parse(locationSearch);

export const getParameterByName = name => {
  let match = RegExp('[?||]' + name + '=([^||]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

/**
 * return [true] if input Value is null or empty, else return [false]
 * @param {*} value value nuder consideration
 */
export const isEmptyOrNull = value => {
  if (value === '' || value === null || value === undefined) return true;
  else if (isArray(value)) return value.length === 0;
  else if (typeof value === 'number') return value < 0;
  else if (typeof value === 'string') return value.length === 0;
  else if (isEmpty(value)) return true;
  else return false;
};

/**
 * helper method to convert the JSON object into a string which can be stored or assigned as URL
 * @param {*} jsonObject JSON Object
 * @param {*} fieldSeparator fieldSeprator used to seprate the Key Value Pairs
 */
export const getStringFromJson = (jsonObject, fieldSeparator = '&') => {
  let arrayOfNonEmptyFields = [];
  Object.keys(jsonObject).forEach(key => {
    if (!isEmptyOrNull(jsonObject[key])) {
      if (isArray(jsonObject[key])) {
        // value is an array
        arrayOfNonEmptyFields.push(key + '=' + jsonObject[key].join(','));
      } else if (typeof jsonObject[key] === 'string') {
        arrayOfNonEmptyFields.push(key + '=' + jsonObject[key]);
      } else {
        arrayOfNonEmptyFields.push(key + '=' + jsonObject[key]);
      }
    }
  });
  return arrayOfNonEmptyFields.join(fieldSeparator);
};

export const dropdownFormat = (value, label) => ({ label, value });

export const sortPaList = (paList, keyName = 'label') => {
  return sortByString(paList, keyName);
};

export const sortSubSectorList = (subSectorList, keyName = 'subSector') => {
  return sortByString(subSectorList, keyName);
};

export const getIconInformation = (
  iconName,
  iconInformation,
  loggedInUserHighestRole
) => {
  if (isEmpty(iconInformation)) return;
  const selectedInformation = iconInformation.filter(item =>
    isEqual(item.name, iconName)
  );
  if (isEmpty(selectedInformation)) {
    return null;
  } else if (selectedInformation.length === 1) {
    return selectedInformation[0].htmlText;
  } else {
    const info = selectedInformation.filter(item =>
      isEqual(item.role, loggedInUserHighestRole)
    );
    if (isEmpty(info)) {
      const information = selectedInformation.filter(item =>
        isEqual(item.role, null)
      );
      return isEmpty(information) ? null : information[0].htmlText;
    } else return info[0].htmlText;
  }
};

/**
 * Check if data is exists or api call is in progress
 * @param {*} response
 */

export const isFetchRequired = response => {
  return isEmpty(response.data) && !response.isLoading && !response.error;
};

/**
 *
 * @param   {String}   role
 * @param   {Array}    impersonatorList
 * @returns {Bpoolean}
 */
export const isImpersonatorRole = role => {
  return isEqual(role, CONFIG.IMPERSONATOR);
};

/**
 *
 * @param   {Array} assignedRolesList - Array of object containing the assigned user role details
 * @returns {Boolean}
 */
export const hasBusinessComparisonRole = assignedRolesList => {
  const isBusinessComparison = assignedRolesList?.some(roleData =>
    isBusinessComparisonRoleId(roleData?.roleId)
  );

  return isBusinessComparison ? true : false;
};

/**
 *
 * @param   {String}  role
 * @param   {Object}  loggedInEmployeeData
 * @returns {Boolean}
 */
export const showBusinessComparison = (role, loggedInEmployeeData) => {
  return (
    isInAdminRoleList(role) ||
    hasBusinessComparisonRole(loggedInEmployeeData?.employeeRoleDTO)
  );
};

/**
 *
 * @param   {String}  role
 * @param   {Object}  loggedInEmployeeData
 * @returns {Boolean}
 */
export const showAdminConsole = role => {
  const adminConsoleRoles = [
    CONFIG.ROLES.SUPER_ADMIN_ROLE,
    CONFIG.ROLES.PA_ADMIN_ROLE,
    CONFIG.ROLES.IMPERSONATOR_ROLE,
  ];
  return adminConsoleRoles.includes(role);
};

/**
 *
 * @param   {String}  role
 * @returns {Boolean}
 */
export const showUserManagementPage = role => {
  return isImpersonatorRole(role);
};

/**
 *
 * @param   {Boolean} isGeoLead - flag for geo lead
 * @returns {Array}             - Array of default managed by values
 */
export const getManagedByDefaultValue = isGeoLead => {
  return isGeoLead
    ? CONFIG.MANAGED_BY_FOR_GEO_DEFAULT_VALUE
    : CONFIG.MANAGED_BY_DEFAULT_VALUE;
};

/**
 *
 * @param   {Boolean} isGeoLead - flag for geo lead
 * @returns {Array}             - Array of all managed by values
 */
export const getManagedByOptions = isGeoLead => {
  return isGeoLead ? CONFIG.MANAGED_BY_FOR_GEO : CONFIG.MANAGED_BY;
};

/**
 *
 * @param   {String} paName
 * @returns {String}         replaces paNmae with alias name if one exist
 */
export const getPaName = paName => {
  return Object.entries(CONFIG.PA_NAME_ALIAS_MAP).reduce(
    (newPa, [pa, paAlias]) =>
      newPa.search(pa) > -1 ? newPa.replace(pa, paAlias) : newPa,
    paName || ''
  );
};

/**
 *
 * @param   { Number } roleId - User role id
 * @returns { Boolean }
 */
export const isAllowedRoleForBusinessComparison = roleId => {
  return CONFIG.ALLOWED_ROLE_FOR_BUSINESS_COMPARISON[Math.trunc(roleId)];
};

/**
 * @param   {Number}  roleId - User role id
 * @returns {Boolean}
 */
export const isAllowedRole = roleId => {
  return !CONFIG.NOT_ALLOWED_ROLES[Math.trunc(roleId)];
};

/**
 * @param   {Number} roleId - User role id
 * @returns {Boolean}
 */
export const isAllPARole = roleId => {
  return CONFIG.ALL_PA_ROLE_IDS[Math.trunc(roleId)];
};

/**
 *
 * @param   {Object}       params
 * @param   {Boolean}      params.passGeo
 * @param   {string}       params.loggedInUserHighestRole
 * @param   {Object}       params.empInitialData
 * @param   {Boolean}      params.isGeoLead,
 * @param   {String|Array} params.managedBy
 * @returns {String|Array}
 */
export const getManagedBy = ({
  passGeo,
  loggedInUserHighestRole,
  empInitialData,
  isGeoLead,
  managedBy,
}) => {
  if (passGeo && !loggedInUserHighestRole) {
    return empInitialData?.managedBy;
  }

  if (!passGeo || isGeoLead) {
    return managedBy;
  }

  return '';
};

/**
 *
 * @param   {String} loggedInUserHighestRole
 * @param   {Object} employeeInitialData
 * @returns {String}
 */
export const getUserRoleName = (
  loggedInUserHighestRole,
  employeeInitialData
) => {
  return (
    loggedInUserHighestRole ||
    (employeeInitialData?.geoMember
      ? CONFIG.ROLES.GEO_TEAM_MEMBER_ROLE
      : CONFIG.ROLES.TEAM_MEMBER_ROLE)
  );
};

/**
 *
 * @param   {Object}        filters
 * @param   {String}        name
 * @param   {String|Object} defaultValue
 * @returns {String|Object}
 */
export const getParameterFromFilters = (filters, name, defaultValue) => {
  if (filters && filters[name]) {
    return name === 'month' ? filters[name] - 1 : filters[name];
  }

  if (getParameterByName(name)) {
    return name === 'month'
      ? getParameterByName(name) - 1
      : getParameterByName(name);
  }

  return defaultValue;
};

/**
 *
 * @param   {String}   role
 * @returns  {Boolean}
 */
export const isGeoLeadRole = role => {
  return role === CONFIG.ROLES.GEO_LEAD_ROLE;
};

/**
 *
 * @param   {String}   role
 * @returns  {Boolean}
 */
export const isGeoManagerRole = role => {
  return role === CONFIG.ROLES.GEO_MANAGER_ROLE;
};

/**
 *
 * @param   {String}   role
 * @returns  {Boolean}
 */
export const isSuperAdminRole = role => {
  return role === CONFIG.ROLES.SUPER_ADMIN_ROLE;
};

/**
 *
 * @param   {String}   role
 * @returns  {Boolean}
 */
export const isInAdminRoleList = role => {
  return CONFIG.ADMIN_ROLES.includes(role);
};

/**
 *
 * @param   {String}   role
 * @returns  {Boolean}
 */
export const isInManagerRoleList = role => {
  return CONFIG.MANAGER_ROLES.includes(role);
};

/**
 *
 * @param   {String}   role
 * @returns  {Boolean}
 */
export const isInGeoAdminRoleList = role => {
  return CONFIG.GEO_LEAD_ROLE_LIST.includes(role);
};

/**
 *
 * @param   {String}   role
 * @returns  {Boolean}
 */
export const isInSuperAdminRoleList = role => {
  return CONFIG.SUPER_ADMIN_ROLES.includes(role);
};

/**
 *
 * @param   {String}  role
 * @returns {Boolean}
 */
export const isInPaAdminRoleList = role => {
  return CONFIG.PA_ADMIN_ROLES.includes(role);
};

/**
 *
 * @param   {String}  role
 * @returns {Boolean}
 */
export const isManagerRole = role => {
  return role === CONFIG.ROLES.MANAGER_ROLE;
};

/**
 *
 * @param   {String}  role
 * @returns {Boolean}
 */
export const isManagerViewerRole = role => {
  return role === CONFIG.ROLES.MANAGER_VIEWER_ROLE;
};

/**
 *
 * @param   {String}  role
 * @returns {Boolean}
 */
export const isPaAdminRole = role => {
  return role === CONFIG.ROLES.PA_ADMIN_ROLE;
};

/**
 *
 * @param   {String}  role
 * @returns {Boolean}
 */
export const isPaAdminViewerRole = role => {
  return role === CONFIG.ROLES.PA_ADMIN_VIEWER_ROLE;
};

/**
 *
 * @param   {String}  role
 * @returns {Boolean}
 */
export const isTeamMemberRole = role => {
  return role === CONFIG.ROLES.TEAM_MEMBER_ROLE;
};

/**
 *
 * @param   {String}  role
 * @returns {Boolean}
 */
export const isGeoTeamMemberRole = role => {
  return role === CONFIG.ROLES.GEO_TEAM_MEMBER_ROLE;
};

/**
 *
 * @param   {String}  role
 * @returns {Boolean}
 */
export const isMemberRole = role => {
  return isTeamMemberRole(role) || isGeoTeamMemberRole(role);
};

/**
 *
 * @param   {Number}  roleId
 * @returns {Boolean}
 */
export const isPaAdminRoleId = roleId => {
  return Math.trunc(roleId) === CONFIG.ROLE_IDS.PA_ADMIN_ROLE_ID;
};

/**
 *
 * @param   {Number}  roleId
 * @returns {Boolean}
 */
export const isSuperAdminRoleId = roleId => {
  return Math.trunc(roleId) === CONFIG.ROLE_IDS.SUPER_ADMIN_ROLE_ID;
};

/**
 *
 * @param   {Object[]} employeeRoleDTO - All employee role info
 * @param   {Object[]} paList          - All PA list
 * @returns {Object}                   - Objects with GeoRegion And Pa code list
 */
export const getUserGeoRegionAndPA = (employeeRoleDTO, paList) => {
  let geoRegions = [];
  let paCodes = [];

  employeeRoleDTO.forEach(roleData => {
    const { roleName, geoRegion, paId } = roleData;
    if (isInPaAdminRoleList(roleName) || isGeoLeadRole(roleName)) {
      if (geoRegion) {
        geoRegions.push(geoRegion);
      }
      if (paId && paId > 0) {
        const paData = paList.find(paInfo => paInfo.paId === paId);
        paCodes.push(paData.paCode);
      }
    }
  });

  return { geoRegions, paCodes };
};

/**
 *
 * @param   {Number} roleId - User role id
 * @returns {Boolean}
 */
export const isBusinessComparisonRoleId = roleId => {
  return Math.trunc(roleId) === CONFIG.ROLE_IDS.BUSINESS_COMPARISON_ROLE_ID;
};

/**
 *
 * @param {Object} history       - History object
 * @param {Object} store         - redux store object
 * @param {Object} ktUserDetails - KT user details from API
 */
export const navigateToUnauthorized = (history, store, ktUserDetails) => {
  store.dispatch(updateFilters({}));
  store.dispatch(updateLoggedInUserHighestRole(''));
  store.dispatch(updateShowPageContentStatus(false));
  history.push({
    pathname: CONFIG.APP_CONST.UNAUTHORIZED,
    state: { userName: ktUserDetails?.userFullName },
  });
};

/**
 *
 * @param   {String}  managedBy - manged by value
 * @returns {Boolean}
 */
export const isGeoUserTypeManagedBy = managedBy => {
  return CONFIG.GEO_USER_TYPES.includes(managedBy);
};

/**
 *
 * @param   {Object}  UserRoleTypes
 * @param   {Boolean} UserRoleTypes.isGeoLead
 * @param   {Boolean} UserRoleTypes.isGeoManager
 * @param   {Boolean} UserRoleTypes.passGeoUrl
 * @param   {Boolean} UserRoleTypes.isAdmin
 * @param   {Boolean} UserRoleTypes.isManager
 * @returns {String}                              - URL for the tableau dashboard
 */
export const getBillabilityTableauURL = ({
  isGeoLead,
  isGeoManager,
  passGeoUrl,
  isAdmin,
  isManager,
}) => {
  if (isGeoLead) {
    return CONFIG.TABLEAU_URL.GEO_LEAD_CASE_BILLABILITY;
  }

  if (isGeoManager) {
    return CONFIG.TABLEAU_URL.GEO_MANAGER_CASE_BILLABILITY;
  }

  if (passGeoUrl) {
    return CONFIG.TABLEAU_URL.GEO_PA_CASE_BILLABILITY;
  }

  if (isAdmin) {
    return CONFIG.TABLEAU_URL.CASE_BILLABILITY;
  }

  if (isManager) {
    return CONFIG.TABLEAU_URL.MANAGER_CASE_BILLABILITY;
  }

  return CONFIG.TABLEAU_URL.MEMBER_CASE_BILLABILITY;
};

/**
 *
 * @param   {boolean} isGeoLead
 * @returns {String}            - URL for the tableau dashboard
 */
export const getBillabilityAdminTableauURL = isGeoLead => {
  return isGeoLead
    ? CONFIG.TABLEAU_URL.GEO_ADMIN_CASE_BILLABILITY
    : CONFIG.TABLEAU_URL.ADMIN_CASE_BILLABILITY;
};

/**
 *
 * @param   {Object}  UserRoleTypes
 * @param   {Boolean} UserRoleTypes.isGeoManager
 * @param   {Boolean} UserRoleTypes.passGeoUrl
 * @param   {Boolean} UserRoleTypes.isManager
 * @returns {String}                              - URL for the tableau dashboard
 */
export const getBillabilitySplitTableauURL = ({
  isGeoManager,
  passGeoUrl,
  isManager,
}) => {
  if (isGeoManager) {
    return CONFIG.TABLEAU_URL.GEO_MANAGER_CASE_BILLABILITY_SPLIT;
  }

  if (passGeoUrl) {
    return CONFIG.TABLEAU_URL.GEO_MEMBER_CASE_BILLABILITY_SPLIT;
  }

  if (isManager) {
    return CONFIG.TABLEAU_URL.CASE_BILLABILITY_SPLIT;
  }

  return CONFIG.TABLEAU_URL.MEMBER_CASE_BILLABILITY_SPLIT;
};

/**
 *
 * @param {Boolean} isGeoManager
 * @param {Boolean} passGeoUrl
 * @returns {String}              - URL for the tableau dashboard
 */
export const getSizeInFTETableauURL = (isGeoManager, passGeoUrl) => {
  if (isGeoManager) {
    return CONFIG.TABLEAU_URL.GEO_MANAGER_SIZE_IN_FTE;
  }

  if (passGeoUrl) {
    return CONFIG.TABLEAU_URL.GEO_MEMBER_SIZE_IN_FTE;
  }

  return CONFIG.TABLEAU_URL.SIZE_IN_FTE;
};

/**
 *
 * @param   {Object}  UserRoleTypes
 * @param   {Boolean} UserRoleTypes.isGeoManager
 * @param   {Boolean} UserRoleTypes.passGeoUrl
 * @param   {Boolean} UserRoleTypes.isGeoLead
 * @param   {Number}  UserRoleTypes.geoUserIndex
 * @returns {String}                              - URL for the tableau dashboard
 */
export const getRequestSatisfactionTableauURL = ({
  isGeoLead,
  isGeoManager,
  passGeoUrl,
  geoUserIndex,
}) => {
  if (isGeoLead) {
    return CONFIG.TABLEAU_URL.GEO_REQUEST_SATISFACTION;
  }

  if (isGeoManager) {
    return CONFIG.TABLEAU_URL.GEO_MANAGER_RMS;
  }

  if (passGeoUrl) {
    return geoUserIndex === 1
      ? CONFIG.TABLEAU_URL.GEO_PA_REQUEST_SATISFACTION
      : CONFIG.TABLEAU_URL.GEO_REQUEST_SATISFACTION;
  }

  return CONFIG.TABLEAU_URL.REQUEST_SATISFACTION;
};

/**
 *
 * @param   {Object}  UserRoleTypes
 * @param   {Boolean} UserRoleTypes.isGeoManager
 * @param   {Boolean} UserRoleTypes.passGeoUrl
 * @param   {Boolean} UserRoleTypes.isGeoLead
 * @param   {Boolean} UserRoleTypes.isManager
 * @param   {Number} UserRoleTypes.geoUserIndex
 * @returns {String}                              - URL for the tableau dashboard
 */
export const getKTPulseTableauURL = ({
  isGeoLead,
  isGeoManager,
  passGeoUrl,
  geoUserIndex,
  isManager,
}) => {
  if (isGeoLead) {
    return CONFIG.TABLEAU_URL.GEO_KT_PULSE_MEMBER;
  }

  if (isGeoManager) {
    return CONFIG.TABLEAU_URL.GEO_MANAGER_KT_PULSE_MEMBER;
  }

  if (passGeoUrl) {
    return geoUserIndex === 1
      ? CONFIG.TABLEAU_URL.GEO_PA_KT_PULSE_MEMBER
      : CONFIG.TABLEAU_URL.GEO_KT_PULSE_MEMBER;
  }

  if (isManager) {
    return CONFIG.TABLEAU_URL.KT_PULSE;
  }

  return CONFIG.TABLEAU_URL.KT_PULSE_MEMBER;
};

/**
 *
 * @param   {String} detailedViewPage        - detailed view page
 * @param   {String} loggedInUserHighestRole - User role
 * @param   {String} managedBy               - managed by value
 * @returns {String}                         - URL for the tableau dashboard
 */
export const getTableauUrl = (
  detailedViewPage,
  loggedInUserHighestRole,
  managedBy
) => {
  let url, activeSheetName, activeSheetName2;
  const isAdmin = isInAdminRoleList(loggedInUserHighestRole);
  const isManager = loggedInUserHighestRole ? true : false;
  const isGeoLead = isGeoLeadRole(loggedInUserHighestRole);
  const isGeoManager = isGeoLead || isGeoManagerRole(loggedInUserHighestRole);
  const geoUserIndex = CONFIG.GEO_USER_TYPES.indexOf(managedBy);
  const passGeoUrl = isGeoUserTypeManagedBy(managedBy);

  switch (detailedViewPage) {
    case CONFIG.DETAILED_VIEW_TOKEN.CASE_BILLABILITY:
      url = getBillabilityTableauURL({
        isGeoLead,
        isGeoManager,
        passGeoUrl,
        isAdmin,
        isManager,
      });
      break;
    case CONFIG.DETAILED_VIEW_TOKEN.CASE_BILLABILITY_ADMIN:
      url = getBillabilityAdminTableauURL(isGeoLead);
      break;
    case CONFIG.DETAILED_VIEW_TOKEN.CASE_BILLABILITY_SPLIT:
      url = getBillabilitySplitTableauURL({
        isGeoManager,
        passGeoUrl,
        isManager,
      });
      break;
    case CONFIG.DETAILED_VIEW_TOKEN.SIZE_IN_FTE:
      url = getSizeInFTETableauURL(isGeoManager, passGeoUrl);
      break;
    case CONFIG.DETAILED_VIEW_TOKEN.REQUEST_SATISFACTION:
      url = getRequestSatisfactionTableauURL({
        isGeoLead,
        isGeoManager,
        passGeoUrl,
        geoUserIndex,
      });
      break;
    case CONFIG.DETAILED_VIEW_TOKEN.KT_PULSE:
      url = getKTPulseTableauURL({
        isGeoLead,
        isGeoManager,
        passGeoUrl,
        geoUserIndex,
        isManager,
      });
      break;
    case CONFIG.DETAILED_VIEW_TOKEN.BILLABILITY_TREND_BY_TEAM_MEMBER:
      url = CONFIG.TABLEAU_URL.BILLABILITY_TREND_BY_TEAM_MEMBER;
      activeSheetName =
        CONFIG.TABLEAU_WORKSHEET.BILLABILITY_TREND_BY_TEAM_MEMBER;
      activeSheetName2 =
        CONFIG.TABLEAU_WORKSHEET.BILLABILITY_TREND_BY_TEAM_MEMBER_HEADER;
      break;
    case CONFIG.DETAILED_VIEW_TOKEN.BILL_RATE_BY_TEAM_MEMBER:
      url = CONFIG.TABLEAU_URL.BILL_RATE_BY_TEAM_MEMBER;
      activeSheetName = CONFIG.TABLEAU_WORKSHEET.BILL_RATE_BY_TEAM_MEMBER;
      break;
    default:
      url = '';
      activeSheetName = '';
      activeSheetName2 = '';
  }

  return { url, activeSheetName, activeSheetName2 };
};

/**
 *
 * @param   {Object} filterDto
 * @param   {String} filterDto.hierarchy
 * @param   {String} filterDto.metricType
 * @returns {Object}                       - object with keys isValid(true|false) and errorMessage
 */
export const validateBusinessComparisonFilters = ({
  hierarchy,
  metricType,
}) => {
  let errorMessage = '';
  let isValid = true;
  if (!hierarchy) {
    errorMessage = 'Please select hierarchy';
    isValid = false;
  } else if (!metricType) {
    errorMessage = 'Please select a metric';
    isValid = false;
  }

  return { isValid, errorMessage };
};

/**
 *
 * @param   {Object}   collectionInfo
 * @param   {String}   collectionInfo.collectionName
 * @param   {Object[]} collectionInfo.collectionList
 * @param   {Boolean}  collectionInfo.fromEditCollection
 * @param   {Object}   collectionInfo.editSectionDetails
 * @returns {Object}   - object with keys isValid(true|false) and errorMessage
 */
export const validateCollectionName = ({
  collectionName,
  collectionList,
  fromEditCollection,
  editSectionDetails,
}) => {
  const isCollectionExist = collectionList?.filter(item =>
    isEqual(item.name, collectionName)
  );

  if (isEmpty(collectionName)) {
    return {
      isValid: false,
      errorMessage: '* Please enter collection name',
    };
  }

  if (collectionName.length > 35) {
    return {
      isValid: false,
      errorMessage:
        '* Collection name length should be less than 35 characters.',
    };
  }

  if (!isEmpty(isCollectionExist) && !fromEditCollection) {
    return {
      isValid: false,
      errorMessage: '* User collection already exists with same name.',
    };
  }

  if (
    !isEmpty(isCollectionExist) &&
    fromEditCollection &&
    !isEqual(collectionName, editSectionDetails.name)
  ) {
    return {
      isValid: false,
      errorMessage: '* User collection already exists with same name.',
    };
  }

  return {
    isValid: true,
  };
};

/**
 *
 * @param   {String} roleName
 * @param   {String} geoRegion
 * @returns {string}
 */
export const getRoleName = ({ roleName, geoRegion }) => {
  if (!isEmpty(roleName)) {
    if (isManagerRole(roleName)) {
      return 'PA Manager';
    }

    if (isManagerViewerRole(roleName)) {
      return 'PA Manager (Viewer)';
    }

    return roleName;
  }

  return geoRegion ? 'Geo Member' : 'Team Member';
};

/**
 *
 * @param   {*} condition
 * @param   {*} trueValue
 * @param   {*} falseValue
 * @returns {*}             - returns the trueValue or falseValue based on evaluated contition
 */
export const selectOption = (condition, trueValue, falseValue) => {
  return condition ? trueValue : falseValue;
};

/**
 *
 * @param   {String} size
 * @returns {Number}
 */
export const getSkeletonWidth = size => {
  let width = 0;
  switch (size) {
    case 'xs':
      width = 30;
      break;
    case 'small':
      width = 60;
      break;
    case 'medium':
      width = 80;
      break;
    case 'large':
      width = 120;
      break;
    case 'x-large':
      width = 150;
      break;
    case 'xx-large':
      width = 200;
      break;
    case 'xxx-large':
      width = 250;
      break;
    default:
      width = 100;
      break;
  }

  return width;
};

/**
 *
 * @param {Object} event
 */
export const stopImmediatePropagation = event => {
  event && event.nativeEvent.stopImmediatePropagation();
};

/**
 *
 * @param   {Object} date - Date object
 * @returns {Number}      - Integer indicating the quarter of the year for the date given
 */
export const getQuarterOfTheYear = date => {
  let month = date.getMonth() + 1;
  return Math.ceil(month / 3);
};

/**
 *
 * @param   {Object}       empParams
 * @param   {Array}        empParams.selectedTeamMembers     - list of selcted employeeIds
 * @param   {Boolean}      empParams.loadAllTeamMember
 * @param   {Array}        empParams.memberList              - list of all the members
 * @param   {String}       empParams.loggedInUserHighestRole
 * @param   {Object}       empParams.employeeInitialData
 * @returns {string|Array}
 */
export const getEmpIdParam = ({
  selectedTeamMembers,
  loadAllTeamMember,
  memberList,
  loggedInUserHighestRole,
  employeeInitialData,
}) => {
  const memberListLength = memberList?.length;
  const empId = getEmpID(loggedInUserHighestRole, employeeInitialData);

  let Id = '';
  if (empId) {
    Id = empId;
  } else if (loadAllTeamMember) {
    Id = 'All';
  } else if (
    !isEmpty(selectedTeamMembers) &&
    !isEqual(memberListLength, selectedTeamMembers?.length)
  ) {
    Id = selectedTeamMembers;
  } else {
    Id = 'All';
  }

  return Id;
};

/**
 *
 * @param   {String} loggedInUserHighestRole
 * @param   {Object} employeeInitialData
 * @returns {String}
 */
export const getEmpID = (loggedInUserHighestRole, employeeInitialData) => {
  let empId = '';
  if (!loggedInUserHighestRole) {
    empId = employeeInitialData?.employeeIdHRO || '';
  }
  return empId;
};

export const getHeaderProps = pathname => {
  const PAGES = CONFIG.KT_SCORECARD_CONST;
  switch (pathname) {
    case PAGES.HOME_URL: {
      return {
        page: pathname,
      };
    }
    case PAGES.BUSINESS_COMPARISON: {
      return {
        hideFilterHeader: true,
        showMultiSelectHeader: true,
      };
    }
    case PAGES.ADMIN_PAGE_URI:
      return {
        fromAdmin: true,
        showLogo: true,
        hideSwitchHeader: true,
      };
    case PAGES.MANAGE_NEW_USER_PAGE_URI:
    case PAGES.MANAGE_EXISTING_USER_PAGE_URI:
      return {
        hideFilterHeader: true,
      };
    default:
      return {};
  }
};

/**
 *
 * @param   {String|Number} data
 * @param   {Object}        columnConf
 * @returns {String}
 */
export const renderColumnData = (data, rowData, columnConf) => {
  const { renderColumn } = columnConf;
  if (typeof renderColumn === 'function') {
    return renderColumn(data, rowData);
  } else if (renderColumn?.type === 'Date' && renderColumn?.format) {
    return formatDate(data, renderColumn?.format);
  } else {
    return data;
  }
};

/**
 *
 * @param   {Object} error - error returned by file uploader
 * @returns {String}
 */
export const getFileUploadErrorMessage = error => {
  return error?.code === 'file-invalid-type'
    ? 'File type must be .xsl or .xlsx'
    : error.message;
};

/**
 * @param  {Object[]} - Array of users list object
 * @return {Obecjt[]} - returns proced user list
 */
export const processUserList = userList => {
  return userList.map(userInfo => {
    userInfo.sector = userInfo.sector || 'All';
    userInfo.subSector = userInfo.subSector || 'All';

    return userInfo;
  });
};

/**
 *
 * @param {Object}     employeeInitialData
 * @param {String}     loggedInUserHighestRole
 * @returns {object[]} Array of objects containg the menu items data
 */
export const getSidebarMenuItems = (
  employeeInitialData,
  loggedInUserHighestRole
) => {
  const tableauUrl = CONFIG.KT_SCORECARD_CONST.KT_TABLEAU_URL;
  const pageBaseUrl = CONFIG.KT_SCORECARD_CONST;
  return [
    {
      key: 'KT_home',
      text: 'Home',
      link: CONFIG.PROTOTYPE_URLS.HOME,
      showItem: true,
    },
    {
      key: 'home',
      text: 'Legacy Dashboard Home',
      link: pageBaseUrl.HOME_URL,
      showItem: true,
    },
    {
      key: 'business-comparison',
      text: 'Business Comparison',
      link: pageBaseUrl.BUSINESS_COMPARISON,
      showItem: showBusinessComparison(
        loggedInUserHighestRole,
        employeeInitialData
      ),
    },
    {
      key: 'billability',
      text: 'Billability trend and top clients',
      link: `${tableauUrl}${CONFIG.DETAILED_VIEW_TOKEN.CASE_BILLABILITY}`,
      showItem: true,
      showBorder: true,
    },
    {
      key: 'billability_trend',
      text: 'Billability trend by team member',
      link: `${tableauUrl}${CONFIG.DETAILED_VIEW_TOKEN.BILLABILITY_TREND_BY_TEAM_MEMBER}`,
      showItem: true,
    },
    {
      key: 'complete_billability',
      text: 'Billability by team member',
      link: pageBaseUrl.EMPLOYEE_BILLABILITY,
      showItem: true,
    },
    {
      key: 'engagement-depth',
      text: 'Engagement depth by team member',
      link: pageBaseUrl.CASE_ENGAGEMENT_DEPTH_URL,
      showItem: true,
    },
    {
      key: 'cd-billability',
      text: 'CD Billability by team member',
      link: pageBaseUrl.CD_BILLABILITY_URL,
      showItem: true,
    },
    {
      key: 'work-split',
      text: 'Detailed charges and hours',
      link: `${tableauUrl}${CONFIG.DETAILED_VIEW_TOKEN.CASE_BILLABILITY_SPLIT}`,
      showItem: true,
    },
    {
      key: 'current_bill_rate',
      text: 'Bill Rate by Team Member',
      link: `${tableauUrl}${CONFIG.DETAILED_VIEW_TOKEN.BILL_RATE_BY_TEAM_MEMBER}`,
      showItem: loggedInUserHighestRole,
    },
    {
      key: 'kt-pulse',
      text: 'KT Pulse Check trend',
      link: `${tableauUrl}${CONFIG.DETAILED_VIEW_TOKEN.KT_PULSE}`,
      showItem: true,
      showBorder: true,
    },
    {
      key: 'rms',
      text: 'RMS CT Satisfaction trend',
      link: `${tableauUrl}${CONFIG.DETAILED_VIEW_TOKEN.REQUEST_SATISFACTION}`,
      showItem: true,
    },
    {
      key: 'team-size',
      text: 'Team Size trend',
      link: `${tableauUrl}${CONFIG.DETAILED_VIEW_TOKEN.SIZE_IN_FTE}`,
      showItem: true,
    },
    {
      key: 'admin-console',
      text: 'Admin Console',
      link: pageBaseUrl.ADMIN_PAGE_URI,
      showItem: showAdminConsole(loggedInUserHighestRole),
      showBorder: true,
    },
    {
      key: 'manage-new-users',
      text: 'Manage New Users',
      link: pageBaseUrl.MANAGE_NEW_USER_PAGE_URI,
      showItem: showUserManagementPage(loggedInUserHighestRole),
    },
    {
      key: 'manage-existing-users',
      text: 'Manage Existing Users',
      link: pageBaseUrl.MANAGE_EXISTING_USER_PAGE_URI,
      showItem: showUserManagementPage(loggedInUserHighestRole),
    },
    {
      key: 'get-help',
      text: 'Get Help',
      link: CONFIG.MAIL_URL,
      showItem: true,
      showBorder: true,
      isMailLink: true,
    },
  ];
};

/**
 *
 * @param {Boolean} isAllSelected
 * @param {Boolean} isManager
 * @param {Array}   selectedTeamMembers - selected user id list
 * @returns
 */
export const selectUserIdAsTeamMembers = (
  isAllSelected,
  isManager,
  selectedTeamMembers
) => {
  return !isAllSelected && isManager && !isEmpty(selectedTeamMembers);
};

/**
 *
 * @param {Boolean} isAllSelected
 * @param {Array} selectedTeamMembers - selected user id list
 * @returns
 */
export const selectUserIdAsDefaultMember = (
  isAllSelected,
  selectedTeamMembers
) => {
  return isAllSelected || isEmpty(selectedTeamMembers);
};

/**
 *
 * @param {Object}  userIdObject
 * @param {Array}   userIdObject.selectedTeamMembers - selected user id list
 * @param {Array}   userIdObject.defaultMemberList   - default user id list
 * @param {Boolean} userIdObject.isAllSelected
 * @param {Boolean} userIdObject.isManager
 * @returns
 */
export const getSelectedUserId = ({
  selectedTeamMembers,
  defaultMemberList,
  isAllSelected,
  isManager,
}) => {
  if (
    selectUserIdAsTeamMembers(isAllSelected, isManager, selectedTeamMembers)
  ) {
    return selectedTeamMembers;
  }

  if (selectUserIdAsDefaultMember(isAllSelected, selectedTeamMembers)) {
    return defaultMemberList;
  }
};

/**
 *
 * @param {Object}  props          - props object from component
 * @param {Object}  prevProps      - previous props object from component
 * @param {Boolean} fromRmsKTPulse
 * @returns
 */
export const shouldGetTableauStackApiData = (
  props,
  prevProps,
  fromRmsKTPulse = false
) => {
  const { apiRequestData, tableauStringData, isRmsData } = props;
  const { selectedTeamMembers } = tableauStringData;
  const { selectedTeamMembers: prevSelectedTeamMembers } =
    prevProps.tableauStringData;

  const requestDataChanged = !isEqual(apiRequestData, prevProps.apiRequestData);
  const selectedTeamChanged = !isEqual(
    selectedTeamMembers,
    prevSelectedTeamMembers
  );
  const notFromKTPulseOrIsRms = !fromRmsKTPulse || isRmsData;

  return requestDataChanged || (selectedTeamChanged && notFromKTPulseOrIsRms);
};

export function getSectorListWithAll(
  data,
  selectedName,
  selectedId,
  sharedSubSector,
  isRoleForAll
) {
  let sectors = [],
    sectorIds = [],
    sharedSubSectors = [];
  if (isEqual(selectedId, -1) && !isEmpty(data) && isRoleForAll) {
    data.forEach(el => {
      if (el.topicSectorId && !isEqual(el.topicSectorId, -1)) {
        sectors.push(el.sector);
        sectorIds.push(el.topicSectorId);
        sharedSubSectors.push(el.sharedSubSector);
      }
    });
  } else {
    sectors.push(selectedName);
    sectorIds.push(selectedId);
    sharedSubSectors.push(sharedSubSector);
  }
  return [sectors, sectorIds, sharedSubSectors];
}

export function getSubSectorListWithAll(
  data,
  selectedName,
  selectedId,
  sharedSubSector,
  isRoleForAll
) {
  let subSectors = [],
    subSectorIds = [],
    sharedSubSectors = [];
  if (isEqual(selectedId, -1) && !isEmpty(data) && isRoleForAll) {
    data.forEach(el => {
      if (el.subSectorId && !isEqual(el.subSectorId, -1)) {
        subSectors.push(el.subSector);
        subSectorIds.push(el.subSectorId);
        sharedSubSectors.push(el.sharedSubSector);
      }
    });
  } else {
    subSectors.push(selectedName);
    subSectorIds.push(selectedId);
    sharedSubSectors.push(sharedSubSector);
  }

  return [subSectors, subSectorIds, sharedSubSectors];
}

export function returnWithAllValue(value) {
  let returnVal = isArray(value) ? value : [value];
  returnVal =
    returnVal.includes('All') || returnVal.includes(0) ? [] : returnVal;
  return returnVal;
}

export function passAllWithEmpty(value) {
  return isEmpty(value) ? 'All' : value.toString();
}

export const getHeaderTextCaseBillabilitySplit = ({
  isManager,
  isGeoLead,
  showPaAdminHeading,
  showAdminHeading,
}) => {
  let heading = 'Case Billability by Team Member';
  if (!isManager) {
    heading = 'Your Case Billability';
  } else if (isGeoLead && showPaAdminHeading) {
    heading = 'Case Billability by PA';
  } else if (showAdminHeading) {
    heading = 'Case Billability by Topic/Sector';
  }

  return heading;
};

export const isAllSectorSubSectorSelected = ({
  topicSectorId,
  subSectorId,
}) => {
  return (
    (subSectorId === 0 || subSectorId === -1) &&
    (topicSectorId === 0 || topicSectorId === -1)
  );
};

export const isAllPa = ({ paId, topicSectorId, subSectorId }) => {
  return (
    (paId === 0 || (paId, -1)) &&
    isAllSectorSubSectorSelected({ subSectorId, topicSectorId })
  );
};

export const sortByObject = (obj, param) => {
  const order = [],
    res = {};
  Object.keys(obj).forEach(key => {
    const value = Math.round(obj[key][param]);
    return (order[value] = key);
  });

  return order;
};

export const formatNumber = value => {
  return !isNaN(value) && value !== 0 ? Math.round(value) : 0;
};

export const formatNumberPercentage = value => {
  return !isNaN(value) && value !== 0 ? Math.round(value) + '%' : '0%';
};

export const getBillabilityTotal = (values, formatValue) => {
  let total = 0;
  let positiveTotal = 0;
  let negativeTotal = 0;
  Object.entries(values).map(key => {
    if (CONFIG.BILLABILITY_TOTAL_KEYS.includes(key[0])) {
      if (key[1] < 0) {
        negativeTotal += Math.abs(key[1]);
      } else {
        positiveTotal += key[1];
      }
    }
    return (total = negativeTotal + positiveTotal);
  });

  total = formatValue ? formatNumber(total) : total;
  return {
    total: total,
    negativeTotal: negativeTotal,
    positiveTotal: positiveTotal,
  };
};

export const treeFormat = (value, label, children, level) => ({
  id: label,
  label: value,
  children: children,
  level,
});

export const calculateMaxValue = data => {
  let maxValue = 100;
  if (!isEmpty(data)) {
    Object.values(data).map(el => {
      let sum = getBillabilityTotal(el);
      if (maxValue < sum) maxValue = sum;
      return maxValue;
    });
  }
  maxValue = 100 / maxValue;
  maxValue = maxValue.toFixed(4);
  return maxValue;
};

export const getPostData = (appliedFilter, memberList, isAnalystUser) => {
  const { startDate, endDate, selectedUserId, dateFilterType } = appliedFilter;
  let userIds = [];
  if (!isAnalystUser) {
    if (isEmpty(appliedFilter) || isEmpty(startDate) || isEmpty(memberList))
      return;
    if (isEmpty(selectedUserId) && !isEmpty(memberList)) {
      memberList.map(el => userIds.push(el.employeeIdHRO));
    }
  }
  let postData = {
    startDate: startDate,
    endDate: endDate,
    selectedUserId: isEmpty(selectedUserId) ? userIds : selectedUserId,
    dateFilterType:
      !isEmpty(dateFilterType) || appliedFilter?.dateFilterType
        ? dateFilterType
        : 'MONTHLY',
  };
  if (!isEmptyOrNull(appliedFilter?.weekPostedDates)) {
    postData.weekPostedDates = appliedFilter.weekPostedDates;
  }
  if (!isEmptyOrNull(appliedFilter?.allWeekPostedDates)) {
    postData.allWeekPostedDates = appliedFilter.allWeekPostedDates;
  }
  return postData;
};

export const getNestedObjectById = (
  arrayOfNestedObjects,
  identifier,
  identifierKey = 'id'
) => {
  let resultantObject = {};
  forEach(arrayOfNestedObjects, obj => {
    if (obj[identifierKey] === identifier) {
      resultantObject = obj;
      return false;
    } else if (isArray(obj.children) && obj.children.length > 0) {
      resultantObject = getNestedObjectById(
        obj.children,
        identifier,
        identifierKey
      );
      if (!isEmpty(resultantObject)) return false;
    } else {
      return;
    }
  });
  return resultantObject;
};

export const transformRawDataToChartSeries = (
  caseBillabilityValues,
  legends
) => {
  const data = {};
  const sectionCategories = Object.keys(legends);
  let negativeValueTotal = 0;
  sectionCategories.forEach(stackKey => {
    const value = Math.round(caseBillabilityValues[stackKey]);
    if (!data[stackKey]) data[stackKey] = [];
    if (value < 0) {
      negativeValueTotal += value;
    }
    data[stackKey].push(value);
  });

  const metricChartSeries = sectionCategories.map(key => ({
    name: key,
    data: data[key],
    color: legends[key],
    pointWidth: 25,
  }));

  return {
    metricChartSeries: [...metricChartSeries].reverse(),
    negativeValueTotal,
  };
};

export const isBusinessView = selectedTab => {
  return (
    isEqual(selectedTab, 'Business Analytics') ||
    isEqual(selectedTab, 'Business View')
  );
};

export const isMemberView = selectedTab => {
  return (
    isEqual(selectedTab, 'Overview by Member') ||
    isEqual(selectedTab, 'My Dashboard')
  );
};

export const isDetailedView = selectedTab => {
  return isEqual(selectedTab, 'Charges/Hours');
};

export const getPostDataForFilters = (userDetails, postData, isAnalystUser) => {
  if (isEmptyOrNull(postData.selectedUserId) && isAnalystUser) {
    postData.selectedUserId = [userDetails.hrid];
  }
  return postData;
};

export const isAnalyst = role => {
  return isEqual(role, CONFIG.ANALYST_ROLE);
};

export const isManagerUser = role => {
  return isEqual(role, CONFIG.MANAGER_ROLE_NEW);
};

export const isLeaderRole = role => {
  return isEqual(role, CONFIG.LEADER_ROLE);
};

export const isSuperAdminRoleNew = role => {
  return isEqual(role, CONFIG.SUPER_ADMIN_ROLE_NEW);
};

export const getPostDataForBusinessView = (
  paList,
  geoMarketList,
  data,
  defaultData,
  isAnalystUser
) => {
  let dataToPost = { ...data };
  if (isEmptyOrNull(defaultData) && !isAnalystUser) return {};
  if (isEmptyOrNull(paList) && isEmptyOrNull(geoMarketList)) return {};
  /** add values of pa,topic,subopic to business view request payload */
  if (!isEmptyOrNull(paList)) {
    if (
      !isEmptyOrNull(defaultData?.paIds) ||
      !isEmptyOrNull(defaultData?.topicSectorIds) ||
      !isEmptyOrNull(defaultData?.subSectorIds)
    ) {
      dataToPost = {
        ...data,
        ...defaultData,
        selectedUserId: data.selectedUserId,
        geoRegion: defaultData.geoRegion,
      };
      return dataToPost;
    } else {
      const selectedPaValues = getSelectedPaValues(true, [], paList);
      const { paIds, topicSectorIds, subSectorIds } = selectedPaValues;
      dataToPost = { ...data, paIds, topicSectorIds, subSectorIds };
    }
  }
  /** add values of geo to business view request payload */
  if (!isEmptyOrNull(geoMarketList)) {
    if (!isEmptyOrNull(defaultData?.geoRegion)) {
      dataToPost = {
        ...data,
        geoRegion: defaultData.geoRegion,
        paAll: defaultData.paAll,
        paIds: defaultData.paIds,
        topicSectorIds: defaultData.topicSectorIds,
        subSectorIds: defaultData.subSectorIds,
        subSectorAll: defaultData.subSectorAll,
        topicSectorAll: defaultData.topicSectorAll,
      };
    } else {
      let geoToPass = [];
      geoMarketList.map(geo => {
        !isEqual(geo, 'CIS') && geoToPass.push(geo);
      });
      dataToPost = { ...dataToPost, geoRegion: geoToPass };
    }
  }
  return dataToPost;
};

export const getSelectedPaValues = (
  checked,
  selectedValue,
  paList,
  getString
) => {
  let selectedPaValues = {};
  paList.map(pa => {
    let values = getCheckedOption(checked, selectedValue, pa);
    selectedPaValues = { ...selectedPaValues, ...values };
  });
  let paIds = [],
    topicSectorIds = [],
    subSectorIds = [];
  for (let [key, value] of Object.entries(selectedPaValues)) {
    switch (value.type) {
      case 'PRACTICE_AREA':
        getString ? paIds.push(value.label) : paIds.push(value.id);
        break;
      case 'SECTOR':
        getString
          ? topicSectorIds.push(value.label)
          : topicSectorIds.push(value.id);
        break;
      case 'SUB_SECTOR':
        getString
          ? subSectorIds.push(value.label)
          : subSectorIds.push(value.id);
        break;
      default:
        break;
    }
  }
  return { paIds, topicSectorIds, subSectorIds };
};

export const getLowestHierarchy = (paList, geoMarketList) => {
  let filterState = {};
  if (!isLoading(paList)) {
    const selectedPaValues = getSelectedPaValues(true, [], paList.data, true);
    const { paIds, topicSectorIds, subSectorIds } = selectedPaValues;
    if (!isEmpty(subSectorIds))
      filterState = { ...filterState, selectedPa: subSectorIds[0] };
    else if (!isEmpty(topicSectorIds))
      filterState = { ...filterState, selectedPa: topicSectorIds[0] };
    else if (!isEmpty(paIds))
      filterState = { ...filterState, selectedPa: paIds[0] };
  }

  if (!isEmptyOrNull(geoMarketList) && !isLoading(geoMarketList))
    filterState = { ...filterState, selectedGeo: geoMarketList.data[0] };
  return filterState;
};

export const getDefaultFilters = userId => {
  const { startDate, endDate } = selectedDates(
    new Date(),
    '',
    'Last 12 Months',
    true
  );
  const defaultFilters = {
    startDate: startDate,
    endDate: endDate,
    dateFilterType: 'MONTHLY',
    selectedUserId: [userId],
  };
  return defaultFilters;
};

export const getDefaultSelectedPa = (defaultFilters, paTopicsSubtopics) => {
  if (isEmptyOrNull(paTopicsSubtopics) || isEmptyOrNull(defaultFilters))
    return {};
  let selectedValues = {};
  const { paList, topicList, subTopicList } = paTopicsSubtopics;
  const { paIds, topicSectorIds, subSectorIds, paAll, topicSectorAll } =
    defaultFilters;
  if (!isEmptyOrNull(paAll)) {
    selectedValues = getSelectedValues(selectedValues, paList, paAll, true);
  }

  if (!isEmptyOrNull(topicSectorAll)) {
    selectedValues = getSelectedValues(
      selectedValues,
      topicList,
      topicSectorAll,
      true
    );
  }

  if (!isEmptyOrNull(paIds)) {
    selectedValues = getSelectedValues(selectedValues, paList, paIds);
  }
  if (!isEmptyOrNull(topicSectorIds)) {
    selectedValues = getSelectedValues(
      selectedValues,
      topicList,
      topicSectorIds
    );
  }
  if (!isEmptyOrNull(subSectorIds)) {
    selectedValues = getSelectedValues(
      selectedValues,
      subTopicList,
      subSectorIds
    );
  }
  return selectedValues;
};

const getSelectedValues = (selectedValues, list, listToFind, nestToChild) => {
  let selectedValuesToReturn = { ...selectedValues };
  let selectedValuesArray = list.filter(value => listToFind.includes(value.id));
  selectedValuesToReturn = getValuesForSelectedPaTopicSubTopic(
    selectedValuesToReturn,
    selectedValuesArray
  );
  if (nestToChild) {
    selectedValuesToReturn = getChildValues(
      selectedValuesArray,
      selectedValuesToReturn
    );
  }
  return selectedValuesToReturn;
};

const getChildValues = (list, selectedValues) => {
  let selectedValuesToReturn = { ...selectedValues };
  list.map(value => {
    if (value.children) {
      selectedValuesToReturn = getValuesForSelectedPaTopicSubTopic(
        selectedValuesToReturn,
        value.children
      );
      selectedValuesToReturn = getChildValues(
        value.children,
        selectedValuesToReturn
      );
    }
  });
  return selectedValuesToReturn;
};

const getValuesForSelectedPaTopicSubTopic = (selectedValues, list) => {
  let selectedObject = {};
  list.map(value => {
    selectedObject = {
      ...selectedObject,
      [value.code]: {
        checked: true,
        id: value.id,
        label: value.label,
        type: value.type,
        partialChecked: true,
      },
    };
  });
  selectedValues = { ...selectedValues, ...selectedObject };
  return selectedValues;
};
